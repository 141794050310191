import c3 from "c3";
import "../../css/insights_v2/eh-vertical-bar-chart-core.scss";

const DEFAULT_PARAMS = {
    bindTo: null,                       // Element to attach to (node or selector).
    title: "Brand X likelihood to purchase",    // Chart title (It should be the question)
    chartWidth: undefined,              // Chart width in pixels. If null, 100% of parent.
    chartHeight: undefined,             // Chart height in pixels. If null, 100% of parent.

    colors: ["#949283", "#E9D28C"],     // Bar colors, repeated in each category (grouping)
    hideLegend: false,                  // Whether to hide the legend or not.
    hideXAxis: false,                   // Whether to hide the X axis or not.
    columns: [                          // List of values for a specific measure, with first element being the label of the measure.
        // ['Exposed', 30, 200, 100],
        // ['Unexposed', 130, 100, 140],
    ],
    labelsFormat: v => v,                       // If provided, will format the labels of each bar.
    yFormat: v => Math.round(v),                // If provided, will format the labels of each bar.
    yMax: 240,                                  // Maximum value for y axis (it should be our number of respondents)
    yMin: 0,                                    // Minimum value for y axis (provided here to fix a bug when all values are equal)
    gridYShow: true,                            // Tells whether or not to display Y grid lines.
    y2Show: true,                               // Tells whether to show y2 (percentages).
    y1Show: true,                               // Tells whether to show y1 (percentages).
    ticks: 11,                                  // Number of ticks on y1 and y2 axis.
    categories: ["BrandX", "BrandY", "BrandX"], // Categories for grouping (it should be by answer)
    barSpace: 0,                                // Ratio of width specifying distance between consecutive bars of same group.

};

export default (params) => {
    params = {...DEFAULT_PARAMS, ...params};

    return c3.generate({
        // Global chart options
        bindto: params.bindTo,
        size: {
            width: params.chartWidth,
            height: params.chartHeight,
        },
        color: {
            // bars colors (in each group of columns)
            pattern: params.colors,
        },
        padding: {
            top: 24,
            bottom: 48,
        },

        // Chart content options
        data: {
            columns: params.columns,
            type: 'bar',
            labels: {
                format: params.labelsFormat
            },
        },

        // Bar shape options.
        bar: {
            width: {
                ratio: 0.8 // defines the space bewteen groups.
            },
            space: params.barSpace,
        },

        // X, Y, Y2 axis shapes and grouping options.
        axis: {
            x: {
                type: "category",
                show: !params.hideXAxis,
                categories: params.categories,
                label: {
                    text: params.title,
                    position: "outer-center",
                },
            },
            y: {
                show: params.y1Show,
                // ticks go from 0 to yMax
                tick: {
                    count: params.ticks,        // Same number of ticks as in y2.
                    format: params.yFormat,
                },
                max: params.yMax,
                min: params.yMin,
                padding: 0,
            },
            y2: {                               // ticks go from 0% to 100% (11 ticks)
                show: params.y2Show,
                tick: {
                    count: params.ticks,
                    format: d => d * 100 + "%",
                },
            }
        },

        // Grid options.
        grid: {
            y: {
                show: params.gridYShow,
            },
        },

        // legend options.
        legend: {
            show: !params.hideLegend,
        },

        tooltip: {
            format: {
                value: params.yFormat
            }
        }
    });
};