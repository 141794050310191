import { Controller } from 'stimulus'
import AppLifecycle from '../utils/app-lifecycle'

export default class extends Controller {
  static targets = ['listAudience']

  test() {}

  connect() {
    console.log('audience controller')
  }

  add(event) {
    event.preventDefault()

    // Clone the empty_form and update the index.
    const form_idx = $('#id_form-TOTAL_FORMS').val()
    $(this.listAudienceTarget).append(
      $('#empty_form')
        .clone()
        .html()
        .replace(/__prefix__/g, form_idx)
    )
    $('#id_form-TOTAL_FORMS').val(parseInt(form_idx) + 1)

    // Reload init JS.
    AppLifecycle.initJS(this.listAudienceTarget)

    // Update the audience row index.
    const audience_rows = this.element.querySelectorAll('.audience-row')
    audience_rows.forEach((el, i) => {
      el.querySelector('.audience-row-index').innerText = i + 1
    })
  }
}
