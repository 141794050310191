window.onModalImageChange = function (type, content_id) {
  const file = $('#' + content_id).get(0).files[0]
  if (!file) {
    return
  }
  const previewContainer = $('#filePreview-' + content_id)
  const reader = new FileReader()
  reader.onload = function (e) {
    let element = ''
    if (type === 'add') {
      $('.addFileWrapper')
        .removeClass('large-up-1')
        .removeClass('medium-up-1')
        .addClass('large-up-2')
        .addClass('medium-up-2')
        .children('p')
        .show()
    }
    if (file.type.indexOf('image') !== -1) {
      element = document.createElement('img')
      element.src = e.target.result
      previewContainer.html(element)
    } else if (file.type.indexOf('video') !== -1) {
      element = document.createElement('video')
      const sourceMP4 = document.createElement('source')
      sourceMP4.setAttribute('src', e.target.result)
      element.setAttribute('controls', 'controls')
      element.appendChild(sourceMP4)
      previewContainer.html(element)
    }
  }
  reader.readAsDataURL(file)
}
