import d3 from 'd3'
import c3 from 'c3'

export default function createVerticalBarChartImplicit(data, htmlElement, label) {

  const height = 500

  let max_overall = 0
  for(let rows of data.columns) {
    for(let d of rows) {
      if(typeof d != 'string') {
        max_overall = Math.max(d, max_overall)
      }
    }
  }

  // allow some more vertical space
  max_overall += 0

  // fake columns for uplift labels, see https://groups.google.com/forum/#!msg/c3js/ctuRDtG6hmw/fjC2EFPx46EJ
  data.columns.push(['data_uplift'].concat(new Array(data.columns[1].length-1).fill(0)))

  var chart = c3.generate({
    bindto: htmlElement,
    data: {
      x: 'traits',
      columns: data.columns,
      names: {
        data_unexposed_pos: 'Unexposed Positive',
        data_unexposed_neg: 'Unexposed Negative',
        data_exposed_pos: 'Exposed Positive',
        data_exposed_neg: 'Exposed Negative',
        data_uplift: 'Uplift' 
      },
      type: 'bar',
      groups: [
        ['data_unexposed_pos', 'data_unexposed_neg'],
        ['data_exposed_pos', 'data_exposed_neg', 'data_uplift']
      ],
      labels: {
        format: function (v, id, i, j) {
          let label = v + '%';
          if(id === 'data_uplift') {
            const uplift = data.uplift[i];
            const prefix = uplift > 0 ? "⬆" : uplift < 0 ? "⬇" : "=";
            label = `${prefix} ${data.uplift[i]}%`;
          }
          return label;
        },
      },
      colors: {
        data_unexposed_pos: '#d4dce2',
        data_unexposed_neg: '#b7bec6',
        data_exposed_pos: '#54e42b',
        data_exposed_neg: '#d21654',
        data_uplift: function (point) {
          const uplift = data.uplift[point.index];
          if (uplift > 0) return '#2abf83';
          if (uplift < 0) return '#d21654';
          return undefined;
        },
      },
    },
    legend: {
      hide: true
    },
    axis: {
      x: {
        type: 'category', // this needed to load string x value
      },
      y: {
        label: {
          text: 'Negative / Positive',
          position: 'outer-middle'
        },
        tick: {
          count: 3,
          // format: d3.format("$,")
          format: function (d) {
            return '';
          }
        },
        max: max_overall,
        min: -max_overall,
      }
    },
    grid: {
      y: {
        lines: [{
          value: 0
        }]
      }
    },
    tooltip: {
      show: false
    },
    onrendered: after_resize_graph
  });

  // move uplift labels
  function after_resize_graph() {
    let data_exposed_pos_LabelBBoxes = d3.selectAll(".c3-texts-data_exposed_pos > text")[0].map(d => d.getBBox())
    let data_uplift_labels = d3.selectAll(".c3-texts-data_uplift > text")[0]
    data_uplift_labels.map((d,i) => {
      d.setAttribute("y", data_exposed_pos_LabelBBoxes[i].y - 10)
    })
  }

  // move column labels
  var seriesName = 'data_unexposed_pos';
  d3.selectAll(".c3-texts-" + seriesName + " > text").attr("dx", "-35px");
  d3.selectAll(".c3-texts-data_unexposed_neg > text").attr("dx", "-35px");
  d3.selectAll(".c3-texts-data_exposed_pos > text").attr("dx", "35px");
  d3.selectAll(".c3-texts-data_exposed_neg > text").attr("dx", "35px");

}
