import nv from "nvd3";
import d3 from "d3";
import "../../css/insights_v2/eh-pie-gauge.scss";

const INACTIVE_COLOR = "#e0e9f8";

/**
 * Create a pie chart that has a single series, indicating a ratio.
 * @param {number} value The value of the only series.
 * @param {string} svgID The id of the target SVG element to mount onto.
 * @param {string} color A reference to a SVG gradient precedently declared, or a
 *                       regular color.
 */
export default ({value, svgID, color}) => {
    color = color.startsWith("#") ? color : `url(#${color})`;
    const chart = nv.models
        .pieChart()
        .x(d => d.label)
        .y(d => d.value)
        .donut(true) //Turn on Donut mode. Makes pie chart look tasty!
        .donutRatio(0.75) //Configure how big you want the donut hole size to be.
        .color([color, INACTIVE_COLOR])
        .showLegend(false)
        .cornerRadius(true)
        .growOnHover(false)
        .width(120)
        .height(120)
        .title(value.toString() + "%");

    chart.tooltip.enabled(false);

    d3.select("#" + svgID)
        .datum([{value}, {value: 100  - value}])
        .transition()
        .duration(350)
        .call(chart);

    nv.utils.windowResize(chart.update);
}
