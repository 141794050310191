import JSZip from 'jszip'
import saveAs from 'jszip/vendor/FileSaver'
import {slugify} from "./misc";


function resetMessage() {
  document.body.style.cursor = ''
  // TODO enable download button
}

function showMessage(text) {
  document.body.style.cursor = 'progress'
  // TODO disable download button
}

function showError(text) {
  console.log(text);
  alert('Error. Please try again later.')
  document.body.style.cursor = ''
}

function updatePercent(percent) {
  // console.log(percent);
}

/**
 * Downloads file at provided URL.
 * @param url {string} The URL to download from.
 * @return {Promise<{filename:string, data:string}>}
 */
function urlToPromise(url) {
  return new Promise(function (resolve, reject) {
    fetch(url)
      .then(response => {
        // attachment; filename=events_4a9aa941-dbc3-494d-90e3-ccb339263d15.csv
        let contentDisposition = response.headers.get('Content-Disposition')
        let filename = contentDisposition.split('=')[1]
        console.log(filename)
        resolve({
          filename: filename,
          data: response.text()
        })
      })
      .catch(err => reject(err))
  });
}

/**
 * Starts preparing a zip and includes the disclaimer READMEs inside.
 * @param zipPrefix {string} The zip file name, without extension.
 * @return {JSZip} The zip object to zip to use and zip the content.
 */
const prepareZipExport = (zipPrefix) => {
    const zip = new JSZip();
    zip.file(zipPrefix + "/README.md", readme_contents, {binary: false})

    // apparently markdown/.md aren't so common among non-devs :(
    // NOTE this might not be enough to strip md if the contents change
    readme_contents = readme_contents.replace(/\`/g,'').replace(/\n_/g,'\n').replace(/_\n/g,'\n')
    zip.file(zipPrefix + "/README.txt", readme_contents, {binary: false})

    return zip;
}

/**
 * Download files from specified urls and at them to the provided zip, at specified
 * location.
 * @param zip {JSZip} The zip object to add files to.
 * @param filesPrefix {string} The path inside the zip to add files to.
 * @param urls {[string]} List of URLs to download from.
 * @return {[Promise]} An Array of promises to wait on.
 */
const downloadAndAddToZip = (zip, filesPrefix, urlPrefix, urls, params) => (
  urls.map(url => (
    urlToPromise(urlPrefix + url + '?' + params)
      .then(file => {
        const filename = file.filename.replace("implicit_raw_data", "implicit");
        zip.file(`${filesPrefix}/${filename}`, file.data, {binary: false})
      })
      .catch(console.log)
  ))
)

/**
 * Generates a zip file and triggers user download based on the provided zip object.
 * @param zip {JSZip} The zip object used to generate the zip file.
 * @param zipPrefix {string} The zip filename prefix, without extension.
 */
const completeZipExport = (zip, zipPrefix) => {
  zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {level: 1}
    },
    metadata => {
      // let msg = "progression : " + metadata.percent.toFixed(2) + " %";
      // if (metadata.currentFile) {
      //   msg += ", current file = " + metadata.currentFile;
      // }
      updatePercent(metadata.percent | 0);
    })
    .then(blob => {
      resetMessage();
      // see FileSaver.js
      saveAs(blob, zipPrefix + ".zip");
    }, showError)
}

/**
 * Decorates a function to let it set a wait cursor when it begins and reverts it
 * in case of an exception.
 *
 * This method does not revert the cursor back to normal when the function ends as it
 * might be waiting for a Promise to be resolved.
 * @param func {function} Any callable, accepting any number of arguments.
 * @return {function(...[*]): (*|undefined)} The same function decorated to change the
 * cursor on execution.
 */
const withWaitCursorSetup = func => (...args) => {
    try {
      showMessage()
      return func(...args)
    } catch (e) {
      resetMessage()
      throw e
    }
}

const downloadAndZipActivity = withWaitCursorSetup((activity_id, urls, params) => {
  const zipPrefix = "eh_activity_" + activity_id;
  const urlPrefix = "/activities/" + activity_id + "/";
  const zip = prepareZipExport(zipPrefix)
  const searchParams = new URLSearchParams(params)
  // find every checked item
  const downloads = downloadAndAddToZip(zip, zipPrefix, urlPrefix, urls, searchParams.toString())
  Promise.allSettled(downloads).then(() => completeZipExport(zip, zipPrefix));
});


/**
 * Retrieves all the activity Ids for the provided project Id.
 * @param projectId {string} Project Id used.
 * @return {Promise<[{name: string, id: string}]>} A promise resolved with all the activity belonging to
 *                             the provided project.
 */
const fetchActivityIdsForProject = (projectId) => (
    fetch(`/projects/${projectId}/activities`)
        .then(response => response.json())
        .then(json => json.data)
        .catch(showError)
)


export const downloadAndZipProject = withWaitCursorSetup((projectId, urls) => {
  const zipPrefix = "eh_project_" + projectId;
  const zip = prepareZipExport(zipPrefix)

  // Collect activity Ids for this project, then download in separate sub folders
  // activities data.
  fetchActivityIdsForProject(projectId)
    .then(activities => {
      const promises = activities.map(activity => {
        const urlPrefix = `/activities/${activity.id}/`
        return Promise.allSettled(
          downloadAndAddToZip(zip, `${zipPrefix}/${slugify(activity.name)}-${activity.id}`, urlPrefix, urls)
        )
      })
      Promise.allSettled(promises).then(() => completeZipExport(zip, zipPrefix));
    })

});

export default downloadAndZipActivity


let readme_contents = `
# Element Human Data Export

This document provides an overview of how to setup and use the Element Human data export files. If you have any more questions, contact Element Human customer support.

For more information on how to use the Workbench please refer to our support site, https://support.elementhuman.com or contact our support team at support@elementhuman.com.


## Files

Note: <activity_id> refers to the unique ID assigned to the exported activity, e.g. 92955ca4-c1f6-11ea-84e2-6b8b66434d1a


This ZIP file contains the following files:

  - activity_config_<activity_id>.csv: Activity configuration data for each component

  - survey_responses_<activity_id>.csv: Respondent-level survey response data

  - implicit_<activity_id>.csv: Respondent-level implicit data

  - timeseries_<activity_id>.csv: Activity-level time series data
`
