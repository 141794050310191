import AppLifecycle from '../utils/app-lifecycle'

AppLifecycle.ready(function() {
  if (!AppLifecycle.serveTrackingTags()) return
  // Send data to Segment via analytics.js
  analytics.page()
  const html = document.querySelector('html')
  const userID = html.getAttribute('data-user-id')
  const userIDHash = html.getAttribute('data-user-hashid')
  const userUsername = html.getAttribute('data-user-username')
  const userEmail = html.getAttribute('data-user-email')
  const userName = html.getAttribute('data-user-name')
  if (userID !== 'None') {
    analytics.identify(
      userID,
      {
        username: userUsername,
        name: userName,
        email: userEmail
      },
      {
        Intercom: {
          user_hash: userIDHash
        }
      }
    )
  }
})
