import { Controller } from 'stimulus'
import loadGraphs from '../insights/index'

export default class extends Controller {
  static targets = ['output']

  test() {}

  connect() {
    const data = this.data.get('json-data')
    const parsedData = JSON.parse(data)
    loadGraphs(parsedData.completes, parsedData.pie_chart_non_complete_color)
  }
}
