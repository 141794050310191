import {Controller} from 'stimulus'
import {
    createVerticalBarChart,
    createPieGauge,
    createHorizontalMultipleBarChart,
    createWorldMap,
    createVideoControls, createKeyMomentsVideo,
} from '../insights_v2'
import waitThumbnail from '../../images/thumbnail-loading.gif';


const precisionFormatter = precision => {
    if (typeof precision === "number") {
        return value => value.toFixed(precision)
    }
    return v => `${v}`;
};

export default class extends Controller {

    static noop = () => {
    };

    connectors = new Map([
        ["verticalBarChart", this.connectVerticalBarChart],
        ["pieGauge", this.connectPieGauge],
        ["horizontalMultipleBarChart", this.connectHorizontalMultipleBarChart],
        ["worldMap", this.connectWorldMap],
        ["videoPlayerControls", this.connectVideoPlayerControls],
        ["keyMomentsVideoCore", this.connectKeyMomentsVideo],
        ["instagramHeatmap", this.connectInstagramHeatmap],
    ]);

    getConnector(name) {
        if (!this.connectors.has(name)) {
            console.error("Missing Insights connector for control:", name);
            return Controller.noop;
        }
        return this.connectors.get(name)
    }

    /**
     * Connect will dispatch the connection to the appropriate handler, based on
     * data-insights-connector attribute.
     */
    connect() {
        this.getConnector(this.data.get('connector')).call(this);
    }

    connectVerticalBarChart() {
        const chart = JSON.parse(this.data.get('options'));
        const precisionFormat = precisionFormatter(chart.precision);

        let extras = {};
        if (chart.labelsOnTop) {
            this.element.className += " labels-on-top";
        }

        switch (chart.unit) {
            case "absolute":
                // Values are provided as absolute numbers and should not be processed.
                extras = {
                    y2Show: false,
                    yFormat: value => precisionFormat(value),
                    labelsFormat: value => precisionFormat(value),
                };
                break;
            case "absolute-percentage":
                // Values are provided as pre-computed percentages.
                extras = {
                    y2Show: false,
                    yFormat: value => `${precisionFormat(value)}%`,
                    labelsFormat: value => `${precisionFormat(value)}%`,
                };
                break;
            case "relative":
                // Values are provided as absolute numbers and should be shown as
                // percentages relative to the max.
                extras = {
                    y2Show: false,
                    yFormat: value => `${Math.round(value / params.yMax * 100)}%`,
                    labelsFormat: value => `${Math.round(value / params.yMax * 100)}%`,
                };
                break;
            case "both":
                // Values are provided as absolute numbers and should be shown as
                // both absolute and computed percentages.
                extras = {
                    y2Show: true,
                    labelsFormat: value => `${precisionFormat(value)} (${Math.round(value / params.yMax * 100)}%)`,
                };
                break;
        }

        const params = {
            ...extras,
            bindTo: this.element,
            y1Show: chart.y1Show,
            chartHeight: chart.chartHeight,
            chartWidth: chart.chartWidth,
            title: chart.title || "",
            colors: chart.colors,
            columns: chart.columns,
            yMax: chart.yMax,
            ticks: chart.ticks,
            gridYShow: chart.gridYShow,
            hideLegend: !!chart.hideLegend,
            hideXAxis: !!chart.hideXAxis,
            categories: chart.categories,
            barSpace: chart.barSpace,
        };
        createVerticalBarChart(params);
    }

    generateAndSetIdForSVG(prefix) {
        const svg = this.element.querySelector("svg");
        const uniqueID = prefix + Math.random().toString(36).substr(2, 9);
        svg.id = uniqueID;
        return uniqueID;
    }

    connectPieGauge() {
        const gauge = JSON.parse(this.data.get('options'));
        createPieGauge({
            value: gauge.value,
            svgID: this.generateAndSetIdForSVG("pie-gauge-"),
            color: gauge.color,
        })
    }

    connectHorizontalMultipleBarChart() {
        const chart = JSON.parse(this.data.get('options'));
        createHorizontalMultipleBarChart({
            data: chart.data,
            svgID: this.generateAndSetIdForSVG("h-multi-bar-chart-"),
            primary: chart.primary,
        })
    }

    connectWorldMap() {
        const map = JSON.parse(this.data.get('options'));
        createWorldMap({
            data: map.data,
            svgID: this.generateAndSetIdForSVG("world-map-"),
            primary: map.primary,
            secondary: map.secondary,
        })
    }

    connectVideoPlayerControls() {
        const options = JSON.parse(this.data.get('options'));
        const videoContainer = document.querySelector(options.videoContainerSelector);
        const player = videoContainer.querySelector("video.layer-player");
        const heatmap = videoContainer.querySelector("video.layer-heatmap");
        const timeBar = videoContainer.querySelector(".timebar");
        createVideoControls({
            player,
            heatmap,
            timeBar,
            container: this.element.querySelector(".player__controls")
        })
    }

    connectKeyMomentsVideo() {
        const options_raw = this.data.get('options');
        if(!options_raw) return;
        const options = JSON.parse(options_raw);
        const id = this.element.id;
        const player = this.element.querySelector(".key-moments-container video.layer-player");
        const heatmap = this.element.querySelector(".key-moments-container video.layer-heatmap");
        this.element.querySelector(".donwload-heatmap").href = options.heatmap;
        createKeyMomentsVideo({
            player,
            heatmap,
            waitThumbnailSrc: waitThumbnail,
            svgChartSelector: `#${id} .layer-chart svg`,
            svgPlotSelector: `#${id} .layer-scatter-plot svg`,
            dataset: options.timeseries,
            moments: options.moments,
            rootElement: this.element,
            videoSrc: options.video,
            heatmapSrc: options.heatmap
        })
    }

    connectInstagramHeatmap() {
        const mediaURL = this.data.get('options');
        const mediaNotReadyDiv = this.element.querySelector("div");
        const targetImage = this.element.querySelector("img");

        const imgTest = new Image();
        let checkInterval = null;

        imgTest.onload = () => {
            clearInterval(checkInterval);
            mediaNotReadyDiv.style.opacity = "0";
            setTimeout(() => {
                targetImage.src = mediaURL
            }, 500);
        };

        checkInterval = setInterval(() => {
            imgTest.src = mediaURL
        }, 5000);
        imgTest.src = mediaURL;
    }
}