import feather from "feather-icons";

function initComponentsAfterLoad(parentNode) {
  console.log('initComponentsAfterLoad', Date.now())
  feather.replace();

  if(parentNode) {
    htmx.process(document.body);
    M.Modal.init(parentNode.querySelectorAll('.modal:not(.no-autoinit)'));
  }

  // activity duplicate
  $('.activity-duplicate-button').on('click', function(e) {
    var _this = $(this);
    _this.css('opacity', 0.5);
    _this.prop('disabled', true);

    var modal = _this.parents('.modal');
    var href = _this.data('href');
    var selected_project = modal.find('.selected-project').val();
    href += "?project_id="+ selected_project;
    modal.find('.modal-error').text('');

    $.ajax({
      url: href,
      success: function() {
        Turbolinks.visit(window.location.href)
        // M.Modal.getInstance(modal).close();
      },
      error: function(data) {
        modal.find('.modal-error').text('Error: ' + data.responseText);
        _this.prop('disabled', false);
        _this.css('opacity', 1.0);
      }
    });
  });  
}

window.initComponentsAfterLoad = initComponentsAfterLoad
