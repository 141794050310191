export default function simpleLineChartLayer(data, htmlElement, label, yDomain, xDomain) {
    return new Promise(resolve => {
        nv.addGraph(function () {
            const chart = nv.models
                .lineChart()
                .interpolate('cardinal')
                .useInteractiveGuideline(true)
                .showLegend(true)
                .showYAxis(true)
                .showXAxis(false)
                .duration(500)
                .noData("")
                // .height(height)
                .clipEdge(false)
            // .isArea(true)

            if (yDomain)
                chart.yDomain(yDomain)
            if (xDomain)
                chart.xDomain(xDomain)


            chart.showLegend(false);
            // chart.xAxis.axisLabel.margin({ bottom: 10 })
            chart.xAxis.axisLabel('Products')

            chart.xAxis
                .axisLabel('Time (ms)')
                .tickPadding(10)
                .tickFormat((d) => d + ' ms')
                .staggerLabels(true)

            chart.yAxis
                .tickPadding(20)
                // .axisLabel(label)
                .tickFormat(d3.format(',.3f'))

            // not working! :(
            // chart.tooltip.valueFormatter((d, i, p) => { return d3.format(',.9f')(d) })

            d3.select(htmlElement)
                .datum(data)
                // .attr('height', height)
                .call(chart)

            d3.select(htmlElement + ' .nv-axislabel')
                .datum(data)
            // .attr('y', 45)

            nv.utils.windowResize(chart.update)

            // Prevent default click behaviour on the legend: it makes the assumption that if
            // you disable all series, you want to show them all back.
            chart.legend.updateState(false);
            // Instead, add our own simple listener to legend clicks.
            chart.legend.dispatch.on('legendClick', function (d, i) {
                d.disabled = !d.disabled;
                chart.update();
            });
            resolve(chart);
            return chart
        })
    })
}
