import "../../css/insights_v2/eh-world-map-core.scss";
import d3 from "d3";
import nv from "nvd3";
import simpleLineChartLayer from "../insights/graphs/simple-line-chart-layer";
import {formatTime} from "./eh-video-controls";
import {tagPlayerAsUnavailable} from "./utils";

const identity = a => a;

/**
 * Config for series we display on the graph.
 * dataKey is the key of the timeseries in the dataset
 * key is the display name key used outside, especially by the filtering buttons.
 */
const GRAPH_SERIES = [
    // emotions
    {dataKey: 'anger', key: 'anger', color: '#d21654'},
    {dataKey: 'disgust', key: 'disgust', color: '#15a661'},
    {dataKey: 'fear', key: 'fear', color: '#6f0286'},
    {dataKey: 'happy', key: 'happiness', color: '#f9be00'},
    {dataKey: 'sad', key: 'sadness', color: '#00428a'},
    {dataKey: 'surprise', key: 'surprise', color: '#e0e0e0'},

    // metrics
    // arousal is a deprecated metric. If expression is provided, it should be
    // preferred. The frontend is supposed to receive only one of them.
    {dataKey: 'arousal', key: 'arousal', color: '#f74100'},

    {dataKey: 'attention', key: 'attention', color: '#00a0ff'},
    {dataKey: 'valence', key: 'valence', color: '#b1158e'},
];

/**
 * Layer filters are buttons displayed below the control.
 * They help disabling parts of the control (video, key moments, series)
 * This function initialises filter buttons behaviour.
 */
const initLayerFilters = (rootElement) => {
    Array.from(rootElement.querySelectorAll('.video_layers_filters input'))
        .forEach(checkbox => {

            // Change look of buttons when clicked and disable/enable matching layer.
            checkbox.addEventListener('click', function (e) {
                const layer = rootElement.querySelector('.layer-' + e.target.value);
                layer.style.visibility = e.target.checked ? 'visible' : "hidden";
                if (e.target.checked) {
                    e.target.parentElement.classList.add("selected");
                } else {
                    e.target.parentElement.classList.remove("selected");
                }
            });
        });
};


/**
 * Processes the raw timeseries points to yield series in a format expected by nvd3.
 * @param filterFunc A function filtering the points to display on the graph.
 * @return {function(*, {transform?: *, key?: *, dataKey?: *}): *}
 */
const mapSeriesToValues = filterFunc => (dataset, {transform = identity, dataKey}) => dataset
    .filter((point) => filterFunc(dataKey, point))
    .map(({timestamp: x, model_output}) => ({x, y: transform(model_output[dataKey])}));


/**
 * Prcoess the raw dataset to yield a dataset in a format expected by nvd3.
 * @param mapValues A function used to map raw series to expected series.
 * @return {function(*=): ({dataKey: string, color: string, key: string}|{dataKey: string, color: string, key: string}|{dataKey: string, color: string, key: string}|{dataKey: string, color: string, key: string}|{dataKey: string, color: string, key: string}|{values: *})[]}
 */
const makeTimeseries = mapValues => dataset => GRAPH_SERIES
    .map(series => ({...series, values: mapValues(dataset, series)}))
    .filter(series => series.values.length > 0);


/**
 * Initialises emotion and metric filter buttons.
 * @param rootElement The root HTMLELement containing the buttons.
 * @param seriesKeys Keys of the related time series. This should match the value of
 * the input buttons.
 * @param onFilterEmotions Callback triggered when a button is clicked and called with
 *
 */
const initEmotionFilters = (rootElement, seriesKeys, onFilterEmotions) => {
    // Create an initial map where all series are enabled.
    const emotionsShown = new Map(seriesKeys.map(key => [key, true]));
    Array
        .from(rootElement.querySelectorAll(".legend-item-wrapper input"))
        .forEach(checkbox => checkbox.addEventListener("click", function () {
            emotionsShown.set(this.value, !emotionsShown.get(this.value));
            onFilterEmotions(emotionsShown);
        }));
};


const _PERCENTILE_FMTS = {"1": "st", "2": "nd", "3": "rd"};
const formatBenchmarkPercentile = value => `(Percentile: ${value}${_PERCENTILE_FMTS[value.toString()] ?? "th"})`;

/**
 * Draws the key moments bubble chart.
 * @param data The processed time series.
 * @param htmlElement The htmlElement (svg) to attach the graph to.
 * @param xDomain The forced X domain. (forcing is required so that both graphs overlap)
 * @param yDomain The forced Y domain.
 * @param thumbnailsHolder a Map or object holding for each emotion data key the generated
 *                         moment thumbnail image src.
 * @param moments The key moments, as a dict of (emotion key => [timestamp, percentile])
 * @return {Promise<Object>} A promise resolved with the created graph.
 */
const keyMomentsChartLayer = (data, htmlElement, xDomain, yDomain, thumbnailsHolder, moments) => new Promise(resolve => {
    const chart = nv.models
        .scatterChart()
        .clipEdge(false)
        .showYAxis(false)
        .showXAxis(false)
        .showLegend(false)
        .yDomain(yDomain)
        .xDomain(xDomain)
        .noData("")
        .pointRange([200, 200]);

    if (yDomain) {
        chart.yDomain(yDomain);
    }

    chart.tooltip.classes("scatter-plot-chart-tooltip");
    chart.tooltip.gravity("e");
    chart.tooltip.contentGenerator(function (obj) {
        const series = obj.series[0];
        const seriesKey = series.key;
        const seriesDataKey = series.dataKey;
        const percentile = moments[seriesDataKey].find(pt => pt[0] === obj.point.x)?.[1];
        const percentileText = percentile ? formatBenchmarkPercentile(percentile) : "";
        return `<div class="scatter-plot-chart-tooltip-content"><img src="${thumbnailsHolder[seriesDataKey][obj.value]}"><span>${parseFloat(obj.point.y).toFixed(2)} ${seriesKey} ${percentileText}</span></div>`
    });

    d3.select(htmlElement)
        .datum(data)
        .call(chart);

    nv.utils.windowResize(chart.update);

    resolve(chart);
});


/**
 * Computes the Y domain to use for both key moments and engagement graph.
 * @param seriesData The data to compute min and max Y from.
 * @return {[number, number]} The Y Domain to inject in nvd3.
 */
const computeYDomain = seriesData => [0, 1];

const computeXDomain = seriesData => {
    const vals = (seriesData[0] || {}).values;
    return (!vals || vals.length === 0) ? [0, 1] : [vals[0].x, vals[vals.length - 1].x];
};

class MissingTimeseriesError extends Error {
}

/**
 * Returns True if the provided collection of key moments contains the specified
 * timestamp.
 * @param moments [[number, number]] The list of moment points. A tuple
 * (timestamp, percentile).
 * @param timestamp number the timestamp, unix integer.
 */
const emotionMomentsMatchTimestamp = (moments, timestamp) => (
    !!moments.find(pt => pt[0] === timestamp)
);

/**
 * Draws both engagement chart and key moments.
 * @param dataset The raw dataset received from the backend.
 * @param svgChartSelector The htmlElement (svg) to attach the engagement graph to.
 * @param svgPlotSelector The htmlElement (svg) to attach the key moments graph to.
 * @param moments The key moments, as a dict of (emotion key => [timestamp, percentile])
 * @param thumbnailsHolder The thumbnails to display when hovering the key moments.
 */
const drawChartAndKeyMoments = async (dataset, svgChartSelector, svgPlotSelector, moments, thumbnailsHolder) => {
    // Pick key moments data points from the main series based on the moments reference
    const keyMomentPicker = (dataKey, pt) => moments[dataKey] && emotionMomentsMatchTimestamp(moments[dataKey], pt.timestamp);

    // Process dataset twice to generate the adapted datasets.
    const seriesData = makeTimeseries(mapSeriesToValues(identity))(dataset);
    const keyMomentsData = makeTimeseries(mapSeriesToValues(keyMomentPicker))(dataset);
    if (seriesData.length === 0) {
        throw new MissingTimeseriesError();
    }

    // Compute both fixed domains. We need to force them so that graphs overlap.
    const yDomain = computeYDomain(seriesData);
    const xDomain = computeXDomain(seriesData);

    // Draw both charts and get a reference on the drawn charts.
    // It will be required to update graphs based on filtering.
    const seriesChart = await simpleLineChartLayer(seriesData, svgChartSelector, "", yDomain, xDomain);
    const keyMomentsChart = await keyMomentsChartLayer(keyMomentsData, svgPlotSelector, xDomain, yDomain, thumbnailsHolder, moments);

    if (keyMomentsData.length === 0) {
        // So that pointer events go through to the time series
        document.querySelector(svgPlotSelector).parentNode.style.display = "none";
    }

    // Return enough data to be able to apply filtering without recomputing everything.
    return [seriesChart, keyMomentsChart, seriesData, keyMomentsData];
};


/**
 * Updates key moments and engagement charts based on enabled/disabled series.
 * @param seriesChart The already drawn engagement chart.
 * @param keyMomentsChart The already drawn key moments chart.
 * @param emotionsShown The current state of shown emotions, as a Map(emotion key => enabledState)
 * @param svgChartSelector The htmlElement (svg) the engagement graph is attached to.
 * @param svgPlotSelector The htmlElement (svg) the key moments graph is attached to.
 * @param seriesData The processed engagement data.
 * @param keyMomentsData The processed key moments data.
 */
const updateChartAndKeyMoments = (seriesChart, keyMomentsChart, emotionsShown, svgChartSelector, svgPlotSelector, seriesData, keyMomentsData) => {
    // Update series visibility state.
    seriesData.forEach(series => series.disabled = !emotionsShown.get(series.key));
    keyMomentsData.forEach(series => series.disabled = !emotionsShown.get(series.key));

    // Recompute Y domain to match what is now enabled/disabled.
    const yDomain = computeYDomain(seriesData);

    // Refresh domain. DO IT FIRST, BEFORE UPDATING GRAPH. Otherwise it does not work.
    seriesChart.yDomain(yDomain).update();
    // Redraw the graphs with new data.
    d3.select(svgChartSelector).datum(seriesData).call(seriesChart);

    // Same with key moments
    keyMomentsChart.yDomain(yDomain).update();
    d3.select(svgPlotSelector).datum(keyMomentsData).call(keyMomentsChart);
};


/**
 * Draw a thumbnail. Actually, sets the drawing context and moves the video cursor.
 * This Promise will be resolved externally once the thumbnail has been draw.
 */
const windVideoAndDraw = (video, emotion, timestamp, workflowContext) => new Promise((resolve) => {
    workflowContext.emotion = emotion;
    workflowContext.timestamp = timestamp;
    workflowContext.onDoneDrawing = resolve;
    video.currentTime = timestamp / 1000;
});


/**
 * Dra all thumbnails one after another.
 */
const drawThumbnails = async (video, moments, workflowContext) => {
    for (let emotion of Object.keys(moments)) {
        const timestamps = moments[emotion].map(pt => pt[0]);
        for (let timestamp of timestamps) {
            await windVideoAndDraw(video, emotion, timestamp, workflowContext);
        }
    }
};


const isEmptyObject = obj => {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return true;
};

/**
 * Creates and return an object holding for each emotion the list of images to be
 * hydrated with actual thumbnails. The original timestamp is lost here but the order stays the same.
 */
const createThumbnailImages = (video, moments, rootElement, waitThumbnailSrc) => {
    const keyMomentsContainer = rootElement.querySelector(".key_impact_moments");
    keyMomentsContainer.textContent = "";

    if (isEmptyObject(moments)) {
        keyMomentsContainer.style.opacity = "0.7";
        keyMomentsContainer.textContent = "No key moment available.";
    }

    return Object.keys(moments).reduce((emotions, emotion) => {
        emotions[emotion] = moments[emotion]
          .map(pt => pt[0])
          .reduce((timestamps, timestamp) => {
            const div = document.createElement("div");
            const p = document.createElement("p");
            const image = document.createElement("img");
            div.append(image, p);
            image.src = waitThumbnailSrc;
            p.textContent = `[${formatTime(timestamp / 1000)}] - ${emotion}`;
            keyMomentsContainer.appendChild(div);
            timestamps[timestamp] = image;
            image.addEventListener("click", () => {
                video.currentTime = timestamp / 1000;
            });
            return timestamps;
        }, {});
        return emotions;
    }, {});
};

const initThumbnails = (player, videoSrc, moments, thumbnailsHolder, rootElement, waitThumbnailSrc) => {
    // Create a hidden video to extract thumbnails from.
    // Do it first to let the video loading begin.
    const video = document.createElement('video');
    video.setAttribute('crossorigin', 'anonymous');
    video.src = videoSrc;

    setImmediate(() => {
        // Meanwhile, Insert thumbnails images in document for each key moment.
        // They will be empty at first, but that is better to see them updated later than
        // appearing way too late and changing the page layout.
        const imagesPerEmotion = createThumbnailImages(player, moments, rootElement, waitThumbnailSrc);

        // Create a canvas to draw on.
        const canvas = document.createElement('canvas');
        const context = canvas.getContext("2d");

        // This context is used to bubble up information from the function that winds the
        // video. In particular, it will set onDoneDrawing with the underlying Promise
        // resolve function (see windVideoAndDraw)
        const workflowContext = {emotion: null, timestamp: null, onDoneDrawing: null};

        // Painting has to be sequential because video needs to be winded before each
        // thumbnail can be extracted.
        // Prepare the painter handler to draw on context every time the video is winded.
        // Then extract a blob from the canvas to feed images of frontend.
        // Finally, call onDoneDrawing to move on to next thumbnail.
        video.addEventListener("timeupdate", () => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(blob => {
                const url = URL.createObjectURL(blob);
                const emotion = workflowContext.emotion;
                const timestamp = workflowContext.timestamp;
                thumbnailsHolder[emotion][timestamp.toString()] = url;
                imagesPerEmotion[emotion][timestamp.toString()].src = url;
                workflowContext.onDoneDrawing();
            });
        });

        // If video is loaded, start immediately. Otherwise, wait for loading.
        video.addEventListener('loadedmetadata', () => {
            // Resize canvas to desired thumbnail size.
            canvas.width = 150;
            // Use cross-multiplication to get canvas height.
            canvas.height = canvas.width * video.videoHeight / video.videoWidth;
            drawThumbnails(video, moments, workflowContext);
        });
    });
};

const loadHeatmap = (heatmap, heatmapSrc) => {
    
    let onerror = () => {

        heatmap.onerror = null;  // you never know

        // this code is a big retry in case the heatmap is still being processed
        const videoTest = document.createElement("video");
        let checkInterval = null;

        videoTest.onloadedmetadata = () => {
            clearInterval(checkInterval);
            setTimeout(() => {
                heatmap.src = heatmapSrc;
            }, 500);
        };

        checkInterval = setInterval(() => { videoTest.src = heatmapSrc; }, 5000);
        videoTest.src = heatmapSrc;
    };

    heatmap.onerror = onerror;

    // here we set the video, if it's there no harm is done, else retry as above
    heatmap.src = heatmapSrc;
};

/**
 * Initialised the Key moments control.
 */
export default ({ dataset, videoSrc, heatmapSrc, player, heatmap, rootElement, svgChartSelector, svgPlotSelector, moments, waitThumbnailSrc }) => {
    player.onloadedmetadata = (event) => {

        // get new height/width of stim video following max-height cap  calculated by browser after it renders
        const { offsetHeight: height, offsetWidth: width } = event.target,
        playerControlsHeight = document.querySelector('.key-momens-video-player-container .player__controls').offsetHeight;

        // set the parent wrapper to have width and height equal to vid size + 40px for height + playback bar size
        document.querySelector('.key-momens-video-player-container').style = `width: ${width}px; height: ${height + playerControlsHeight}px`;

        loadHeatmap(heatmap, heatmapSrc);
        const thumbnailsHolder = {};
        Object.keys(moments).forEach(emotion => thumbnailsHolder[emotion] = {});
        initThumbnails(player, videoSrc, moments, thumbnailsHolder, rootElement, waitThumbnailSrc);
        initLayerFilters(rootElement);
        drawChartAndKeyMoments(dataset, svgChartSelector, svgPlotSelector, moments, thumbnailsHolder)
            .then(([seriesChart, keyMomentsChart, seriesData, keyMomentsData]) => {
                if (seriesChart && keyMomentsChart) {
                    const onFilterEmotions = emotionsShown => updateChartAndKeyMoments(seriesChart, keyMomentsChart, emotionsShown, svgChartSelector, svgPlotSelector, seriesData, keyMomentsData);
                    initEmotionFilters(rootElement, seriesData.map(series => series.key), onFilterEmotions);
                }
            })
            .catch(e => {
                if (e instanceof MissingTimeseriesError) {
                    tagPlayerAsUnavailable(player);
                }
            });
    };
    player.onerror = () => {
        tagPlayerAsUnavailable(player);
    };
    player.src = videoSrc;

};
