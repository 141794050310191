import { Controller } from 'stimulus'

export default class extends Controller {
  test() {}

  connect() {
    if ($('.errorlist').length !== 0) {
      const element = document.getElementsByClassName('errorlist')[0]
      element.parentElement.parentElement.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }
}
