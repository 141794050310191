export default function createVerticalBarChart(data, htmlElement, label) {
  const height = 300
  nv.addGraph(function() {
    const chart = nv.models
      .multiBarChart()
      .reduceXTicks(false) //If 'false', every single x-axis tick label will be rendered.
      .showControls(false) //Allow user to switch between 'Grouped' and 'Stacked' mode.
      .groupSpacing(0.1) //Distance between each group of bars.
      .height(height)

    chart.legend.margin({ bottom: 60 })

    chart.yAxis //Chart y-axis settings
      .tickPadding(20)
      .axisLabel(label)
      .tickFormat(d3.format(',.1f'))
    chart.yAxis.tickValues(0)

    // const days = [Good', 'Unique', 'Elegant', 'Beautiful']
    chart.xAxis
      .tickPadding(20)
      .tickFormat(d3.format(',f'))
      .tickValues(Array(data.traits.length).fill().map((x,i)=>i))
      .tickFormat(function(d) {
        return data.traits[d]
      })

    d3.select(htmlElement)
      .datum(data.data)
      .attr('height', height)
      .call(chart)

    nv.utils.windowResize(chart.update)

    return chart
  })

  function exampleData() {
    //Line chart data should be sent as an array of series objects.
    return [
      {
        values: [
          { x: 0, y: 0 },
          { x: 1, y: 1 },
          { x: 2, y: 4 },
          { x: 3, y: 3 },
        ], //values - represents the array of {x,y} data points
        key: 'pre', //key  - the name of the series.
        color: '#f9be00' //color - optional: choose your own line color.
      },
      {
        values: [
          { x: 0, y: 0 },
          { x: 1, y: 3 },
          { x: 2, y: 5 },
          { x: 3, y: 7 },
        ],
        key: 'post',
        color: '#15a661'
      }
    ]
  }
}
