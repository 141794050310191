const PROGRESS_POPUP_THRESHOLD =  5 * 1000 * 1000 // 5 MB
const noop = () => {}

/**
 * Returns the size in bytes of the form data to be sent.
 * @param fd {FormData} form data to calculate size of.
 * @return {number} The form size, in bytes.
 */
const getFormDataSize = fd => Array.from(fd.entries())
  .map(([_, prop]) => typeof prop === "string" ? prop.length : prop.size)
  .reduce((res, size) => res + size, 0);


/**
 * Creates a modal to notify the user an upload is in progress.
 * @param modalId {string} Id of the root element of the modal.
 * @param formData {FormData} The form that will be uploaded, to make a duration estimate.
 * @return {[noop, noop, noop]|[function(): void, function(): void, function(): void]} A tuple of [open, update, close] functions to interact with the modal.
 */
export const useUploadProgress = (modalId, formData) => {
  const uploadSize = Math.round(getFormDataSize(formData))
  if (uploadSize < PROGRESS_POPUP_THRESHOLD) {
    // For such a short time, do nothing.
    return [noop, noop, noop];
  }


  const modalEl = document.getElementById(modalId)
  const modal = M.Modal.init(modalEl, {
    dismissible: false,
    preventScrolling: true,
    opacity: 1,
  })
  const progressTextEl = modalEl.querySelector(".progress-text")
  const progressBarEl = modalEl.querySelector(".determinate")
  const startTime = new Date().getTime()

  const openProgress = () => modal.open()
  const closeProgress = () => modal.close()
  const updateProgress =  (loaded, total) => {
    const elapsedSeconds =  Math.round((new Date().getTime() - startTime) / 1000)
    const progressPercent = Math.min(100, Math.round(loaded / total * 100))
    const done = loaded === total
    const indeterminate = loaded > total
    progressTextEl.textContent = done || indeterminate ? `Processing, please wait...` : `Elapsed time: ${elapsedSeconds} seconds`
    if(indeterminate) {
      progressBarEl.classList.add("indeterminate")
      progressBarEl.classList.remove("determinate")
      progressBarEl.style.removeProperty("width") 
    } else {
      progressBarEl.classList.add("determinate")
      progressBarEl.classList.remove("indeterminate")
      progressBarEl.style.width = progressPercent + "%"
    }
  }

  updateProgress(0, 1)

  return [openProgress, updateProgress, closeProgress]
};