import "../css/styles.scss";
import "htmx.org";
import AppLifecycle from "./utils/app-lifecycle";
import "./components/init.js";
import "./components/horizontal-scroll";
import "./components/modal-image-change";
import "./lib/segment";
import "./lib/materialize";
import "./lib/iframeSizer.min";
import domtoimage from "dom-to-image-more";
import saveAs from "jszip/vendor/FileSaver";
import { slugify } from "./utils/misc";
import downloadAndZipActivity, {
  downloadAndZipProject,
} from "./utils/zip-file";

// Add Stimulus
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

// Use Turbolinks
import Turbolinks from "turbolinks";
import nanomorph from "nanomorph";

Turbolinks.SnapshotRenderer.prototype.assignNewBody = function () {
  nanomorph(document.body, this.newBody);
};

const originalCacheSnapshot = Turbolinks.Controller.prototype.cacheSnapshot;
const originalDefer = Turbolinks.defer;
const noDefer = (callback) => callback();
Turbolinks.Controller.prototype.cacheSnapshot = function () {
  Turbolinks.defer = noDefer;
  originalCacheSnapshot.call(this, ...arguments);
  Turbolinks.defer = originalDefer;
};

Turbolinks.start();

window.htmx = require('htmx.org');

AppLifecycle.ready(function () {
  AppLifecycle.initJS();
});

// **** not used any more but could be useful for debug/customer support
window.download_model_data = function () {
  window.open("download_model_events");
  window.open("download_model_results");
  window.open("download_model_surveys");
  window.open("download_model_survey_results");
};

window.download_graph_data = function () {
  window.open("download_graph_survey_implicit");
  window.open("download_graph_survey_responses");
  window.open("download_graph_timeseries");
  window.open("download_reference_data");
  window.open("download_activity_config");
};
// ****

const ACTIVTY_DOWNLOAD_URLS = [
  "download_graph_survey_responses",
  "download_graph_timeseries",
  "download_activity_config",
  "download_implicit_raw_data",
];

window.download_activity_data = function(activity_id) {
  let params = {}, age = $('select[name="age"]').val(), 
    gender = $('select[name="gender"]').val(), country = $('select[name="country"]').val();

  if(age && age.length > 0) params['age'] = age;
  if(gender && gender.length > 0) params['gender'] = gender;
  if(country && country.length > 0) params['country'] = country;

  if(document.location.href.includes("v=2")) params['v'] = 2;

  downloadAndZipActivity(activity_id, ACTIVTY_DOWNLOAD_URLS, params)
}


window.download_project_data = function(projectId) {
  downloadAndZipProject(projectId, ACTIVTY_DOWNLOAD_URLS)
}

window.download_project_data = function (projectId) {
  downloadAndZipProject(projectId, ACTIVTY_DOWNLOAD_URLS);
};

window.download_project_pricing = function (projectId) {
  fetch(`/projects/${projectId}/download_project_pricing`)
    .then((response) => {
      let contentDisposition = response.headers.get("Content-Disposition");
      let filename = contentDisposition.split("=")[1];
      response.blob().then((blob) => saveAs(blob, filename));
    })
    .catch(console.log);
};

// TODO move to a better place?
window.copyEmbedCodeToClipboard = function () {
  let codeArea = document.querySelector("#embed_code");
  codeArea.select();

  navigator.clipboard.writeText(codeArea.textContent).then(
    function () {
      // give some kind of decend feedback
      console.log("copied");
    },
    function () {
      console.log("not copied");
    }
  );
};

window.copyEmbedRedirectUrlToClipboard = function () {
  let codeArea = document.querySelector("#redirect_url");
  codeArea.select();

  navigator.clipboard.writeText(codeArea.textContent).then(
    function () {
      // give some kind of decend feedback
      console.log("copied");
    },
    function () {
      console.log("not copied");
    }
  );
};

let copyId = null

window.copyEhSurveyUrlToClipboard = function (el) {
  if (copyId) return 

  let urlField = document.querySelector("#ehSurveyUrl");
  urlField.select();

  navigator.clipboard.writeText(urlField.value).then(
    function () {
      // give some kind of decend feedback
      console.log("copied");

      el.innerText = 'Copied';

      copyId = setTimeout(() => { 
        el.innerText = 'Copy';
        copyId = null;
      }, 1000)
    },
    function () {
      console.log("not copied");
    }
  );
};

// TODO duplicated from survey.js file
const GENERIC_PANEL_RESPONDENT_ID_PARAM = "resp_id";
const GENERIC_PANEL_EH_RESPONDENT_ID_PARAM = "ehid";
const GENERIC_PANEL_REDIRECT_URL_PARAM = "redirect_url";

window.prepareEmbedRedirectIncomingUrl = function (
  activity_id,
  redirect_url,
  resp_id
) {
  return `https://activity.elementhuman.com/activities/${activity_id}/survey_complete/?${GENERIC_PANEL_REDIRECT_URL_PARAM}=${encodeURIComponent(
    redirect_url
  )}&${GENERIC_PANEL_RESPONDENT_ID_PARAM}=${resp_id}`;
};

window.prepareEmbedRedirectOutgoingUrl = function (redirect_url, resp_id) {
  try {
    let url = new URL(redirect_url);
    url.searchParams.append("status", false ? "screenedout" : "complete");
    url.searchParams.append(GENERIC_PANEL_RESPONDENT_ID_PARAM, resp_id);
    url.searchParams.append(
      GENERIC_PANEL_EH_RESPONDENT_ID_PARAM,
      "db021a7a-9543-11ea-883b-b3ebf6eba052"
    );
    return url.toString();
  } catch (error) {
    return "<error: malformed URL>";
  }
};

window.updateEmbedRedirectUrls = function (activity_id, redirect_url, resp_id) {
  document.querySelector("#redirect_url").textContent =
    prepareEmbedRedirectIncomingUrl(activity_id, redirect_url, resp_id);
  document.querySelector("#outbound_redirect_url").textContent =
    prepareEmbedRedirectOutgoingUrl(redirect_url, resp_id);
};

const HTML2CANVAS_OPTIONS = {
  ignoreElements: (el) => el.classList.contains("export-button"),
  backgroundColor: null,
};

/**
 * Prints and download the specified DOM element.
 * @param element {HTMLElement} The HTML Element to print.
 * @param title {string} the title of the element. It will be slugified to be used as
 *                       filename.
 */
window.printElement = (element, title) => {
  domtoimage
    .toBlob(element, {
      filter: (el) => !el.classList || !el.classList.contains("export-button"),
    })
    .then(function (blob) {
      saveAs(blob, slugify(title) + ".png");
    })
    .catch((e) => console.log(e.toString()));
};

window.reprocess_activity = (activity_id) => {
  if (
    !window.confirm(
      "This function should be used for troubleshooting only, using it without supervision can lead to unreliable results.\n\nAre you sure you want to process the insights for this activity?"
    )
  )
    return;

  fetch(`/insights/${activity_id}/reprocess`)
    .then((response) => {
      window.alert(
        "Processing requested. Please wait for the process to complete.\n\nYou can close this page now, or refresh it in a few minutes."
      );
    })
    .catch(console.log);
};

// handler for active status of sexy tooltip widget -> templates/layouts/audience_attributes.html
document.addEventListener("click", function (e) {
  if (e.target && e.target.classList.contains("sexy-tooltip-toggler")) {
    if (e.target.classList.contains("active")) {
      e.target.classList.remove("active");
    } else {
      e.target.classList.add("active");
    }
  }
});
