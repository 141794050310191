import "../../css/insights_v2/eh-video-controls.scss";


function createTimeBar(player, timeBar) {
    const timeBarContainer = timeBar.parentElement;
    const timeBarLabel = timeBar.querySelector(".timebar-label");

    player.addEventListener('timeupdate', () =>  {
        let barPositionX = Math.round(timeBarContainer.clientWidth * player.currentTime / player.duration);
        timeBar.style.left = barPositionX + "px";
        timeBarLabel.textContent = formatTime(player.currentTime);
    });
}

export function formatTime(seconds) {
    seconds = Math.round(seconds);
    return `${Math.floor(seconds / 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`
}

/**
 *
 */
export default ({player, heatmap, timeBar, container}) => {
    const progress = container.querySelector('.player__controls_progress');
    const progressBar = container.querySelector('.progress__filled');
    const toggle = container.querySelector('.toggle');
    const skipButtons = container.querySelectorAll('[data-skip]');
    const ranges = container.querySelectorAll('.player__slider');
    const videoProgressTime = container.querySelector('.player__progress_time');
    const videoDuration = container.querySelector('.player__duration');

    createTimeBar(player, timeBar);

    const isPlayerStopped = () => player.paused || player.ended;

    function togglePlay() {
        if (isPlayerStopped()){
            player.play();
            heatmap.play();
        } else {
            player.pause();
            heatmap.pause();
        }
    }

    function spaceBarTogglePlay(e) {
        if (e.keyCode === 32) {
            togglePlay()
        }
    }

    function updateButton() {
        toggle.textContent = this.paused ? '►' : '❚❚';
        if (isPlayerStopped()){
            heatmap.pause();
        } else {
            heatmap.play();
        }
    }

    function skip() {
        player.currentTime += parseFloat(this.dataset.skip);
        heatmap.currentTime = player.currentTime;
    }

    function handleRangeUpdate() {
        this.setAttribute('title', this.value);
        player[this.name] = this.value;
        heatmap[this.name] = this.value;
    }

    function handleProgress() {
        const percent = (player.currentTime / player.duration) * 100;
        videoProgressTime.textContent = formatTime(player.currentTime);
        progressBar.style.flexBasis = `${percent}%`
    }

    function scrub(e) {
        player.currentTime = (e.offsetX / progress.offsetWidth) * player.duration;
        heatmap.currentTime = player.currentTime;
    }

    function videoEnded() {
        heatmap.pause();
        heatmap.currentTime = 0;
    }

    player.addEventListener('click', togglePlay);
    player.addEventListener('play', updateButton);
    player.addEventListener('pause', updateButton);
    player.addEventListener('ended', videoEnded);
    player.addEventListener('timeupdate', handleProgress);
    player.addEventListener('durationchange', function () {
        videoDuration.textContent = formatTime(player.duration);
    });

    toggle.addEventListener('click', togglePlay);
    document.addEventListener('keypress', spaceBarTogglePlay);
    skipButtons.forEach(button => button.addEventListener('click', skip));
    ranges.forEach(range => range.addEventListener('change', handleRangeUpdate));
    ranges.forEach(range => range.addEventListener('mousemove', handleRangeUpdate));

    let mousedown = false;
    progress.addEventListener('click', scrub);
    progress.addEventListener('mousemove', e => mousedown && scrub(e));
    progress.addEventListener('mousedown', () => (mousedown = true));
    progress.addEventListener('mouseup', () => (mousedown = false))
}
