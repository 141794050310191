/**
 * Transforms free text into a slug.
 * @param text {string} the text to transform.
 * @return {string} The slugified text.
 */
export const slugify = text => (
  text.toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')             // Trim - from end of text
)
