export default function createPieChart({
  data,
  htmlElement,
  color,
  nonCompleteColor,
  showTitle
}) {
  color = color.startsWith("#") ? color : `url(#${color})`;
  nv.addGraph(function() {
    const chart = nv.models
      .pieChart()
      .x(d => d.label)
      .y(d => d.value)
      .duration(3000)
      .donut(true) //Turn on Donut mode. Makes pie chart look tasty!
      .donutRatio(0.75) //Configure how big you want the donut hole size to be.
      .color([color, nonCompleteColor])
      .showLegend(false)
      .cornerRadius(true)
      .growOnHover(false)
      .height(230)

    // Add a title in the middle of the pie chart for gender outputs only. The summary
    // scores have images instead.
    if (showTitle) chart.title(data[0].value + "%")

    chart.tooltip.enabled(false)

    d3.selectAll(htmlElement)
      .datum(data)
      .attr('height', '230')
      .call(chart)

    nv.utils.windowResize(chart.update)

    return chart
  })
}
