import { Controller } from 'stimulus'
import Turbolinks from 'turbolinks'
import axios from 'axios'
import M from 'materialize-css/dist/js/materialize.min.js'

import AppLifecycle from '../utils/app-lifecycle'
import {useUploadProgress} from "../utils/upload-progress";


export default class extends Controller {
  static targets = ['output']

  test() {}

  load() {
    this.outputTarget.innerHTML = ''

    const modal = M.Modal.init(this.outputTarget, {
      dismissible: false,
      preventScrolling: false,
      onOpenEnd: function() {
        $('#id_name').focus()
      },
      onCloseStart: () => document.querySelectorAll('video').forEach((vid) => vid.pause())
    })
    modal.open()

    fetch(this.getUrlWithLanguage())
      .then(response => response.text())
      .then(html => {
        this.loadForm(html)
      })
  }

  save(event) {
    event.preventDefault()
    var _form = this.form;

    // prevent double submits
    if (_form.classList.contains('is-submitting')) {
      return;
    }
    _form.classList.add('is-submitting');


    const formData = new FormData(_form)
    const [openProgressModal, updateProgressModal, closeProgressModal] = useUploadProgress("contentUploadProgress", formData);
    openProgressModal();

    let requestUrl = this.getUrlWithLanguage()
    axios.request({
      url: requestUrl,
      method: "POST",
      data: formData,
      onUploadProgress: progress => {
        let loaded = progress.loaded
        if(progress.loaded == progress.total) {
          loaded = progress.total + 1
        }
        updateProgressModal(loaded, progress.total);
      },

    })
      .then(response => {
        closeProgressModal();

        // sick way to manage a redirect-after-modal-closes because axios wouldn't tell 302...
        let redirect_url = window.location.href
        if(response.request.responseURL.indexOf(requestUrl) < 0) redirect_url = response.request.responseURL 

        // NOTE this code is used by all modals, and true branch has been retrofitted, at some point...
        // The original behaviour (false branch) assumes that there is an empty response in case of success,
        // while an actual HTML response (a page) would imply there have been an error.
        // Based on this assumption, the modal would either remain opened (reloaded to show server-side
        // generated page with errors) or be closed.
        // The new behaviour simplifies the case of explicit-managed error (non-Django managed).
        if(response.headers["content-type"] == 'application/json') {
          let responseErrors = response.data["errors"]
          let hasError = !!responseErrors

          if (hasError) {
            _form.querySelector('#errors-text').innerText = responseErrors
            _form.classList.remove('is-submitting')
            _form.querySelector('input.btn').style.opacity = 1.0
          } else {
            Turbolinks.visit(redirect_url)
          }
        } else {
          let html = response.data
          let hasError = html.includes('data-error')

          if (hasError) {
            this.loadForm(html)
          } else {
            Turbolinks.visit(redirect_url)
          }
        }
      })
  }

  loadForm(html) {
    this.outputTarget.innerHTML = html
    AppLifecycle.initJS(this.outputTarget)
    this.form.addEventListener('submit', this.save.bind(this))
  }

  get url() {
    return this.data.get('url')
  }

  get form() {
    return this.element.querySelector('form')
  }

  getUrlWithLanguage() {
    // TODO move this in the right place
    let selected_language_code = document.querySelector('#selected_language_code')
    let url = this.url
    if(selected_language_code) {
      url += '?language_code=' + selected_language_code.value
    }
    return url
  }
}
