export default function createHorizontalBarChart(data, htmlElement) {
  nv.addGraph(function() {
    const chart = nv.models
      .multiBarHorizontalChart()
      .x(function(d) {
        return d.label
      })
      .y(function(d) {
        return d.value
      })
      .margin({ left: 150 })
      .showControls(false) //Allow user to switch between "Grouped" and "Stacked" mode.
      .showLegend(false)
      .barColor(['#2ecc71'])
      .fillOpacity(1)
      .groupSpacing(0.7)
      .height(220)

    chart.tooltip.enabled(true)

    chart.xAxis.tickPadding(20)
    chart.yAxis.tickPadding(20).tickFormat(d3.format(',f'))

    d3.select(htmlElement)
      .datum(data)
      .attr('height', '220')
      .transition()
      .duration(500)
      .call(chart)

    $(htmlElement)
      .find('rect')
      .attr('rx', '5')

    nv.utils.windowResize(chart.update)

    return chart
  })
}
