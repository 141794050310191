const AppLifecycle = {}

AppLifecycle.ready = function(queuedFunction) {
  window.setTimeout(() => {
    // for async loading of the main script
    if (document.readyState !== 'loading') {
      queuedFunction()
    }
    document.addEventListener('turbolinks:load', queuedFunction)
  })
}

AppLifecycle.destroy = function(queuedFunction) {
  document.addEventListener('turbolinks:before-render', queuedFunction)
}

AppLifecycle.loadScript = function(src) {
  return new Promise(function(resolve, reject) {
    let scripts = Array.from(document.querySelectorAll('script')).map(
      scr => scr.src
    )

    if (scripts.includes(src)) {
      resolve()
      return
    }

    const s = document.createElement('script')
    s.src = src
    s.async = true
    s.onload = resolve
    s.onerror = reject
    document.head.appendChild(s)
  })
}

AppLifecycle.serveTrackingTags = function() {
  return document.body.getAttribute('data-serve-tracking-tags') === 'True'
}

AppLifecycle.initJS = function(target) {
  M.AutoInit(target)

  const datepickerElements = $('.datepicker')
  const instances = M.Datepicker.init(datepickerElements, {
    setDefaultDate: true,
    firstDay: 1,
    showDaysInNextAndPreviousMonths: true,
    container: 'body'
  })

  const datepickerModalElements = $('.datepicker-modal')
  M.Modal.init(datepickerModalElements, {
    dismissible: false
  })

  for (let i = 0; i < instances.length; i++) {
    instances[i].el.addEventListener('select', () => {
      instances[i].open()
    })
  }

  initComponentsAfterLoad()

  setTimeout(() => {
    M.updateTextFields()
  }, 100)

  // If some scripts have been appended and want to be loaded, execute them on the fly.
  if (target) {
    $(target)
        .find('script[data-app-lifecycle-load="true"]')
        .each(function (index, script) {
          eval($(script).text())
        });
  }

  // TODO find a better place?
  let selected_language_code = document.querySelector('#selected_language_code')
  if(selected_language_code) {
    let params = new URL(document.location.href).searchParams;
    let language_code = params.get('language_code')
    if(language_code) {
      selected_language_code.value = language_code
    }

    selected_language_code.addEventListener('change', (event) => {
      let url = new URL(document.location.href);
      url.searchParams.set('language_code', event.target.value)
      document.location.href = url
    });  
  }

}

export default AppLifecycle
