function handleScrollClick(e) {
  const animateDelay = 500
  const singleCardWidth = $('.single-activity-card').width()
  const singleCardPadding = Number(
    $('.single-activity-card')
      .css('padding-left')
      .replace('px', '')
  )
  const scrollOffset = (singleCardWidth + singleCardPadding) * 2
  const [direction, containerPrefix] = e.id.split('-')
  const i = $(`#${containerPrefix}-container`).scrollLeft()
  const maxScrollWidth =
    $(`#${containerPrefix}-container`).get(0).scrollWidth -
    $(`#${containerPrefix}-container`).get(0).clientWidth

  let scrollValue

  if (direction === 'left') {
    scrollValue = i - scrollOffset
    $(`#right-${containerPrefix}`).removeClass('handle-disabled')
    if (scrollValue <= 0) {
      $(e).addClass('handle-disabled')
    }
  }
  if (direction === 'right') {
    scrollValue = i + scrollOffset
    $(`#left-${containerPrefix}`).removeClass('handle-disabled')
    if (scrollValue >= maxScrollWidth) {
      $(e).addClass('handle-disabled')
    }
  }
  $(`#${containerPrefix}-container`).animate(
    {
      scrollLeft: scrollValue
    },
    animateDelay
  )
}

window.handleScrollClick = handleScrollClick
