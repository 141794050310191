import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['index']

  test() {}

  connect() {
    console.log('audience controller')
  }

  delete(event) {
    event.preventDefault()

    $(this.element).hide()

    const delete_box = this.element.querySelector('[type="checkbox"]')
    $(delete_box).prop('checked', true)

    // Update the audience row index.
    const audience_rows = this.element.querySelectorAll('.audience-row')
    audience_rows.forEach((el, i) => {
      el.querySelector('.audience-row-index').innerText = i + 1
    })
  }
}
