import nv from "nvd3";
import d3 from "d3";
import "../../css/insights_v2/eh-horizontal-multiple-bar-chart.scss";

/**
 * Creates an horizontal multiple bar chart east oriented.
 * @param {[{value: number, label: string}]} data The chart data, as a list of
 *                                           objects (label, value)
 * @param {string} svgID Id of the svg element to mount onto.
 * @param {string} primary Primary color for bars.
 * @param {number} height Height of the graph in pixels.
 */
export default ({data, svgID, primary, height = 220}) => {
    const chart = nv.models
        .multiBarHorizontalChart()
        .x(d => d.label)
        .y(d => d.value)
        .margin({left: 100})
        .showControls(false)    // Don't show Group/Stack switch button
        .showLegend(false)
        .barColor([primary])
        .fillOpacity(1)
        .groupSpacing(0.7)
        .height(height);

    chart.tooltip.enabled(true);
    chart.xAxis.tickPadding(20);
    chart.yAxis.tickPadding(20).tickFormat(d3.format(',f'));

    d3.select("#" + svgID)
        .datum([{values: data}])
        .attr('height', height.toString())
        .transition()
        .duration(500)
        .call(chart);

    // Add a pretty radius to bars.
    $("#" + svgID).find('rect').attr('rx', '2');

    nv.utils.windowResize(chart.update);
}
