import 'd3'
import 'nvd3'
import createPieChart from './graphs/pie-chart'
import createHorizontalBarChart from './graphs/horizontal-multiple-bar-chart'
import createVerticalBarChart from './graphs/vertical-multiple-bar-chart'
import createVerticalBarChartImplicit from './graphs/vertical-multiple-bar-chart-implicit'
import createWorldMap from './graphs/world-map.js'

export default function loadGraphs(
  completes,
  pie_chart_non_complete_color
) {
  let scoreData = getScoreData()

  for (let scoresId of ["head-summary-score", "footer-summary-score"]) {
    createPieChart({
      data: scoreData.attention,
      htmlElement: `#${scoresId} .summary-attention-pie-chart svg`,
      color: '#54e42b',
      nonCompleteColor: pie_chart_non_complete_color
    })
    createPieChart({
      data: scoreData.expression,
      htmlElement: `#${scoresId} .summary-expression-pie-chart svg`,
      color: '#54e42b',
      nonCompleteColor: pie_chart_non_complete_color
    })
    createPieChart({
      data: scoreData.memory,
      htmlElement: `#${scoresId} .summary-memory-pie-chart svg`,
      color: '#54e42b',
      nonCompleteColor: pie_chart_non_complete_color
    })
  }

  const attention_scores = document.getElementsByClassName(
    'summary-attention-score-value'
  )
  for (let i = 0; i < attention_scores.length; i++) {
    attention_scores[i].innerText = Math.round(scoreData.attention[0].value)
  }

  const expression_scores = document.getElementsByClassName(
    'summary-expression-score-value'
  )
  for (let i = 0; i < expression_scores.length; i++) {
    expression_scores[i].innerText = Math.round(scoreData.expression[0].value)
  }

  const memory_scores = document.getElementsByClassName(
    'summary-memory-score-value'
  )
  for (let i = 0; i < memory_scores.length; i++) {
    memory_scores[i].innerText = Math.round(scoreData.memory[0].value)
  }

  let genderData = getGenderData()
  createPieChart({
    data: genderData.male,
    htmlElement: '#male-pie-chart svg',
    color: '#00a0ff',
    nonCompleteColor: pie_chart_non_complete_color,
    showTitle: true
  })
  createPieChart({
    data: genderData.female,
    htmlElement: '#female-pie-chart svg',
    color: '#ff2198',
    nonCompleteColor: pie_chart_non_complete_color,
    showTitle: true
  })
  createPieChart({
    data: genderData.other,
    htmlElement: '#others-pie-chart svg',
    color: '#f9be00',
    nonCompleteColor: pie_chart_non_complete_color,
    showTitle: true
  })
  let countryData = getCountryData()
  createHorizontalBarChart(countryData, '#countries-bar-chart svg')
  createWorldMap(countryData, '#world-map-graph')
  createHorizontalBarChart(ageData(), '#age-bar-chart svg')

  document.getElementById(
    'total_respondents'
  ).innerText = totalRespondentsData()
  document.getElementById('age-average').innerText = ageAverageData()

  createVerticalBarChart(
    implicitData('brand_logo_0'),
    '#implicit-bar-chart-0 svg',
    'Main Brand'
  )
  createVerticalBarChart(
    implicitData('brand_logo_1'),
    '#implicit-bar-chart-1 svg',
    'Competitor'
  )

  let implicitShiftData = getImplicitShiftData()

  if (implicitShiftData.uplift.length > 1) {
    createVerticalBarChartImplicit(
      implicitShiftData,
      '#implicit-shifts-bar-chart'
    )

    // clone data structure (JS way...) before passing as it's going to be changed
    fillScorecard(JSON.parse(JSON.stringify(implicitShiftData)))
  }

}


function ageData() {
  let values = []
  const age_breakdown = data_respondents['age_breakdown']
  for (let range in age_breakdown) {
    values.push({ label: range, value: age_breakdown[range] })
  }
  return [
    {
      values: values
    }
  ]
}

function totalRespondentsData() {
  return data_respondents['total_respondents']
}

function ageAverageData() {
  return Math.round(data_respondents['age_average'])
}

function getCountryData() {
  let values = data_respondents['country_breakdown'].map(country => {
    let countryName = country.country ? country.country : 'None'
    if (countryName.indexOf('-') > 0) {
      countryName = countryName.split('-')[0]
    }
    return {
      label: countryName,
      value: country.total
    }
  })
  return [
    {
      values: values
    }
  ]
}

function getScoreData() {
  data_scores.attention *= 100
  data_scores.expression *= 100
  data_scores.memory *= 100

  return {
    attention: [
      { value: data_scores.attention },
      { value: 100 - data_scores.attention }
    ],
    expression: [
      { value: data_scores.expression },
      { value: 100 - data_scores.expression }
    ],
    memory: [{ value: data_scores.memory }, { value: 100 - data_scores.memory }]
  }
}

function getGenderData() {
  let valueMale = 0
  if (data_respondents['gender_breakdown']['male']) {
    valueMale = Math.round(
      (data_respondents['gender_breakdown']['male'] /
        data_respondents['total_respondents']) *
        100
    )
  }

  let valueFemale = 0
  if (data_respondents['gender_breakdown']['female']) {
    valueFemale = Math.round(
      (data_respondents['gender_breakdown']['female'] /
        data_respondents['total_respondents']) *
        100
    )
  }

  let valueOther = 100 - valueMale - valueFemale

  return {
    male: [
      {
        value: valueMale
      },
      {
        value: 100 - valueMale
      }
    ],
    female: [
      {
        value: valueFemale
      },
      {
        value: 100 - valueFemale
      }
    ],
    other: [
      {
        value: valueOther
      },
      {
        value: 100 - valueOther
      }
    ]
  }
}

function implicitData(brand) {
  let brand_data = data_surveys.filter(x => x.brand == brand)
  let traits = brand_data.reduce(
    (traits, x) => (traits.includes(x.trait) ? traits : traits.concat(x.trait)),
    []
  )
  let brand_data_pre = brand_data.filter(x => x.position == 'pre')
  let brand_data_post = brand_data.filter(x => x.position == 'post')
  let data_pre = []
  for (let i = 0; i < brand_data_pre.length; i++) {
    data_pre.push({ x: i, y: brand_data_pre[i].response })
  }
  let data_post = []
  for (let i = 0; i < brand_data_post.length; i++) {
    data_post.push({ x: i, y: brand_data_post[i].response })
  }

  return {
    traits: traits, // ['Good', 'Unique', 'Elegant', 'Beautiful'],
    data: [
      {
        values: data_pre, //values - represents the array of {x,y} data points
        key: 'pre', //key  - the name of the series.
        color: '#b1158e' //color - optional: choose your own line color.
      },
      {
        values: data_post,
        key: 'post',
        color: '#00a0ff'
      }
    ]
  }
}

function getImplicitShiftData() {
  let data_implicit = data_implicit_bbc
  let total_exposed = data_respondents.total_respondents
  let total_unexposed = data_respondents.total_unexposed

  let traits = data_implicit.reduce(
    (traits, x) =>
      traits.includes(x.trait) ? traits : traits.concat([x.trait]),
    []
  )

  let brand_data_exposed = data_implicit.filter(
    x => x.activity_type == 'exposed'
  )
  let brand_data_unexposed = data_implicit.filter(
    x => x.activity_type == 'unexposed'
  )

  let data_unexposed_pos = brand_data_unexposed
    .filter(x => x.opinion == 'YES')
    .map(x => Math.round((x.score / total_unexposed) * 100))
  let data_unexposed_neg = brand_data_unexposed
    .filter(x => x.opinion == 'NO')
    .map(x => Math.round((x.score / total_unexposed) * -100))
  let data_exposed_pos = brand_data_exposed
    .filter(x => x.opinion == 'YES')
    .map(x => Math.round((x.score / total_exposed) * 100))
  let data_exposed_neg = brand_data_exposed
    .filter(x => x.opinion == 'NO')
    .map(x => Math.round((x.score / total_exposed) * -100))

  let total_unexposed_pos = Math.round(
    data_unexposed_pos.reduce((sum, x) => sum + x, 0) / data_unexposed_pos.length
  )
  let total_unexposed_neg = Math.round(
    data_unexposed_neg.reduce((sum, x) => sum + x, 0) / data_unexposed_neg.length
  )
  let total_exposed_pos = Math.round(
    data_exposed_pos.reduce((sum, x) => sum + x, 0) / data_exposed_pos.length
  )
  let total_exposed_neg = Math.round(
    data_exposed_neg.reduce((sum, x) => sum + x, 0) / data_exposed_neg.length
  )

  let uplift = [Math.round((total_exposed_pos / total_unexposed_pos - 1) * 100)]
  for (let i = 0; i < data_exposed_pos.length; i++) {
    uplift.push(
      Math.round((data_exposed_pos[i] / data_unexposed_pos[i] - 1) * 100)
    )
  }

  let data = {
    uplift: uplift,
    columns: [
      ['traits', 'TOTAL'].concat(traits.map(x => '"' + x + '"')),
      ['data_unexposed_pos', total_unexposed_pos].concat(data_unexposed_pos),
      ['data_unexposed_neg', total_unexposed_neg].concat(data_unexposed_neg),
      ['data_exposed_pos', total_exposed_pos].concat(data_exposed_pos),
      ['data_exposed_neg', total_exposed_neg].concat(data_exposed_neg)
    ]
  }

  return data

  // return {
  //   uplift: [422, 160, 500, 260, 182, 164, 713],
  //   columns: [
  //     ['traits', 'TOTAL', '"Business Opps"', '"Entrepreneurship"', '"Heritage"', '"Inspiring"', '"Modern"', '"Trustworthy"'],
  //     ['data_unexposed_pos', 9, 5, 4, 10, 11, 11, 8],
  //     ['data_unexposed_neg', -5, -3, -1, -6, -10, -5, -5],
  //     ['data_exposed_pos', 47, 13, 24, 36, 31, 29, 65],
  //     ['data_exposed_neg', -26, -5, -7, -8, -8, -8, -44],
  //   ],
  // }
}

function fillScorecard(implicitShiftData) {

  // TODO I'm sure there are better ways to do this...
  let uplift = implicitShiftData.uplift
  let traits = implicitShiftData.columns[0].slice()
  
  // remove 'traits'
  traits.shift()
 
  // remove TOTAL from the list so it doens't get involved in Max calcs
  let total_uplift_label = traits.shift()
  let total_uplift_value = uplift.shift()

  let max1 = Math.max(...uplift)
  let max1_index = uplift.indexOf(max1)
  let max1_label = traits[max1_index]
  uplift.splice(max1_index, 1)
  traits.splice(max1_index, 1)
  let max2 = Math.max(...uplift)
  let max2_index = uplift.indexOf(max2)
  let max2_label = traits[max2_index]

  let top_entries = [
    { label: total_uplift_label, uplift: total_uplift_value },
    { label: max1_label, uplift: max1 },
    { label: max2_label, uplift: max2 }
  ]

  for (let i = 0; i < top_entries.length; i++) {
    let arrow_class = top_entries[i].uplift >= 0 ? 'arrow-up' : 'arrow-down'
    document
      .getElementById('scorecard_implicit_arrow_' + i)
      .setAttribute('data-feather', arrow_class)
    document.getElementById('scorecard_implicit_value_' + i).innerText =
      top_entries[i].uplift
    document.getElementById('scorecard_implicit_label_' + i).innerText =
      top_entries[i].label
  }
}
